.error404 {
    background: linear-gradient(to Right, #fff 10%, #fff 20%, #157cfb 70%, #157cfb 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textShine 7s ease-in-out infinite alternate;
    font-size: 85px;
    font-family: Spartan;
    font-weight: bolder;
    text-align: center;
  }
.errormessage{
  background: linear-gradient(to Right, #157cfb 10%, #fff 20%, #fff 70%, #157cfb 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 5s ease-in-out infinite alternate;
  font-size: 25px;
  font-family: Spartan;
  font-weight: bolder;
  text-align: center;
  margin-left: 200px;
  margin-right: 200px;
}
.errormessage2{
  background: linear-gradient(to Right, #157cfb 10%, #fff 20%, #fff 70%, #157cfb 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 5s ease-in-out infinite alternate;
  font-size: 25px;
  font-family: Spartan;
  font-weight: bolder;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

  @keyframes textShine {
    to {
      background-position: 500%;
    }
  }